// ProfileMenu.js
import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { Link as RouterLink, useLocation } from "react-router-dom";

export const ProfileMenu = ({ anchorEl, isMenuOpen, handleMenuClose, handleSignOut, user }) => {
    const location = useLocation();
    const menuId = 'primary-account-menu';

    if (!user) {
        return null;
    }

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {user.isAnonymous &&
                <MenuItem onClick={handleMenuClose} component={RouterLink} to={{
                    pathname: '/sign-in',
                    state: { redirect: location.pathname }
                }}>Sign in</MenuItem>
            }
            {
    !user.isAnonymous && (
        <MenuItem
            onClick={handleMenuClose}
            component={RouterLink}
            to="/create-personalised-pet-photo-gifts"
        >
            My account
        </MenuItem>
    )
}
            {user.isAnonymous ? 
                <MenuItem onClick={handleMenuClose} component={RouterLink} to={{
                    pathname: '/create-account',
                    state: { redirect: location.pathname }
                }}>Create account</MenuItem> : 
                <MenuItem onClick={handleSignOut}>Sign out</MenuItem>}
        </Menu>
    );
};
