// MobileDrawer.js
import React from 'react';
import { SwipeableDrawer, List, ListItem, ListItemText } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
//MOBILE MENU
export const MobileDrawer = ({ isMobileMenuOpen, handleMobileMenuClose, handleMobileMenuOpen, classes }) => {
    return (
        <SwipeableDrawer 
            variant="persistent" 
            anchor="top" 
            open={isMobileMenuOpen} 
            onClose={handleMobileMenuClose}
            onOpen={handleMobileMenuOpen}
            className={classes.drawer}
        >
            <div className={classes.toolbarOffset}></div>
            <List style={{ padding: 0 }}>
                <ListItem className={classes.mobileItems} onClick={handleMobileMenuClose} component={RouterLink} to="/">
                    <ListItemText primary="HOME"/>
                </ListItem>
                 <ListItem className={classes.mobileItems} onClick={handleMobileMenuClose} component={RouterLink} to="/gifts/gifts">
                    <ListItemText primary="GIFTS"/>
                </ListItem>
                <ListItem className={classes.mobileItems} onClick={handleMobileMenuClose} component={RouterLink} to="/gifts/dogs">
                    <ListItemText primary="DOG GIFTS"/>
                </ListItem>
                <ListItem className={classes.mobileItems} onClick={handleMobileMenuClose} component={RouterLink} to="/gifts/cats">
                    <ListItemText primary="CAT GIFTS"/>
                </ListItem>
                <ListItem className={classes.mobileItems} onClick={handleMobileMenuClose} component={RouterLink} to="/gifts/pets">
                    <ListItemText primary="PET GIFTS"/>
                </ListItem>
                <ListItem className={classes.mobileItems} onClick={handleMobileMenuClose} component={RouterLink} to="/create">
                    <ListItemText primary="CREATE"/>
                </ListItem>
                <ListItem className={classes.mobileItems} onClick={handleMobileMenuClose} component={RouterLink} to="/gallery">
                    <ListItemText primary="GALLERY"/>
                </ListItem>
                <ListItem className={classes.mobileItems} onClick={handleMobileMenuClose} component={RouterLink} to="/blog">
                    <ListItemText primary="BLOG"/>
                </ListItem>
            </List>
        </SwipeableDrawer>
    );
};
