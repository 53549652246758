// import React from "react"

const productData = {
    //SECTION PHOTO-TILES
    'pet-photo-tiles': {
        'consistent': {
            'createLink': '/product/photo-tile',
            'price': 'photo-tile',
            'priceFrom': false,
        },
        'head': {
            'title': 'Personalised Pet Photo-tiles | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create your own personalised pet photo tiles easily at Jigpaws by using our dedicated online tool. Pick your pet picture and upload it on a photo tile, instantly!',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-photo-tiles',
            'og_title': 'Create Your Pet Photo Tile | Order Online | Jigpaws',
            'og_description': 'Create your own personalised pet photo tiles easily at Jigpaws by using our dedicated online tool. Pick your pet picture and upload it on a photo tile, instantly!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Pet Photo Tiles with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully personalised pet picture frames has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Personalised Pet Photo and Frame Here',
            'description': 'Creating a stunning piece of art dedicated to your favourite pet has never been easier than with Jigpaws. See your image come to life on a 20 x 20 cm personalised pet photo frame, printed with vivid colour and in high detail. Perfect as a gift for friends, family, or a way to commemorate a beloved companion. \n' +
                '\n' +
                'Our removable pet photo tiles use a specialist adhesive that allows for repositioning and resticking up to five times. Designed for painted walls and a variety of other surfaces, each custom pet photo tile can be easily hung without tools and without the risk of residue.\n' +
                '\n' +
                "All personalised photo tiles come with a plastic frame made from up to 50% recycled materials, beautifully framing your pet's picture.",
            'image': {
                'src': '/images/landingPages/photo-tiles/pet1.jpg',
                'alt': 'Jigpaws personalised gift photo tile with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Simply upload your favourite pet image to see your brand-new, highly detailed, fully personalised pet photo tile spring to life!.',
            'description2': 'Or choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadPhototile.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Add a Style',
            'description': 'Easily flip and reposition your pet picture until your pet stands out on your brand-new personalised pet photo tile. \n' +
                '\n' +
                'For best results, take your image from a good phone, tablet or digital camera. Ensure that your favourite pet is at the centre of the image and well-lit. Creating a fully personalised pet photo tile has never been easier than with us at Jigpaws. With endless possibilities, what are you waiting for? \n',
            'image': {
                'src': '/images/landingPages/photo-tiles/pet2.jpg',
                'alt': 'Stylise your pet photo-tile'
            }
        }, 'panel_4': {
            'title': 'Order Your New Pet Photo Tile To Your Door',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new and fully personalised pet photo tile with you within 2 - 6 working days. International shipping is also available. ',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-tiles/pet3.jpg',
                'alt': 'Order your pet-phototile'
            }
        },
    },
    'dog-photo-tiles': {
        'consistent': {
            'createLink': '/product/photo-tile',
            'price': 'photo-tile',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Dog Photo Tile | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create your own personalised dog photo tiles quickly at Jigpaws by using our dedicated online tool. Pick your dog picture and upload it on a photo tile, today\n',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-photo-tiles',
            'og_title': 'Create Your Pet Photo Tile | Order Online | Jigpaws',
            'og_description': 'Create your own personalised dog photo tiles quickly at Jigpaws by using our dedicated online tool. Pick your dog picture and upload it on a photo tile, today\n',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Dog Photo Tiles with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully personalised dog picture frames has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Personalised Dog Photo Tiles and Frame Here\n',
            'description': 'Creating a stunning piece of art dedicated to your loyal companion has never been easier than with Jigpaws. Your personalised dog photo is printed in high detail and vivid colour. Perfect as a gift for family, or friends or as a way to commemorate a beloved family pet.\n' +
                // '\n' +
                // 'Simply upload a favourite image of your dog and see it come to life on a 20 x 20 cm personalised dog photo tile with a black recycled plastic frame. The high-detail and vivid colour print makes it a perfect gift for family and friends. \n' +
                '\n' +
                "These handmade photo tiles come with an adhesive that allows effortless repositioning on painted walls without the need for tools. All personalised photo tiles come with a black recycled plastic frame displaying your dog's picture.",
            'image': {
                'src': '/images/landingPages/photo-tiles/dog1.jpg',
                'alt': 'Jigpaws personalised gift photo tile with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Personalised Dog Photo',
            'description': 'Simply upload a favourite image of your dog and see it come to life on a 20 x 20 cm personalised dog photo tile with a black recycled plastic frame. The high-detail and vivid colour print makes it a perfect gift for family and friends.',
            'description2': 'Or choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail, using vibrant colour.',
            'image': {
                'src': '/images/landingPages/uploadPhototile.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Add a Style to Your Framed Dog Picture',
            'description': 'Easily flip and reposition your personalised dog photo until your dog stands out and looks its best on your brand-new framed dog picture!\n ' +
                '\n' +
                'For best results, take your image from a good phone, tablet or digital camera. Ensure that the dog is at the centre of the image and under good lighting. \n' +
                '\n' +
                'Creating a fully framed dog picture tile has never been easier than with us at Jigpaws. With endless customisation options and filters, what are you waiting for?',
            'image': {
                'src': '/images/landingPages/photo-tiles/dog2.jpg',
                'alt': 'Stylise your dog photo tile with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your New Dog Photo Tile To Your Door',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new and fully personalised pet photo tile with you within 2 - 6 working days. International shipping is also available. ',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-tiles/dog3.jpg',
                'alt': 'Jigpaws personalised gift photo tile with dog picture'
            }
        },
    },
    'cat-photo-tiles': {
        'consistent': {
            'createLink': '/product/photo-tile',
            'price': 'photo-tile',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Cat Photo Tiles | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create your framed cat picture tiles easily at Jigpaws by using our dedicated online tool. Pick your favourite cat picture and upload it on a photo tile, instantly!',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-photo-tiles',
            'og_title': 'Create Your Pet Photo Tile | Order Online | Jigpaws',
            'og_description': 'Create your framed cat picture tiles easily at Jigpaws by using our dedicated online tool. Pick your favourite cat picture and upload it on a photo tile, instantly!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Cat Photo Tiles with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully personalised cat photo tiles has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Custom Framed Cat Pictures With Jigpaws',
            'description': 'Creating a stunning piece dedicated to your favourite cat has never been easier than with us at Jigpaws. See your cat image come to life on a 20 x 20 cm personalised cat photo tile, printed with vivid colour and in high detail. Perfect as a gift for friends, or family or as a way to cherish or remember a beloved companion.\n' +
                '\n' +
                'Jigpaws’ fully removable cat photo tiles use a special adhesive that allows for repositioning and resticking up to five times on painted walls and other surfaces.',
            'image': {
                'src': '/images/landingPages/photo-tiles/cat1.jpg',
                'alt': 'Jigpaws personalised gift photo tile with cat photo'
            }
        }, 'panel_2': {
            'title': 'Add an Image to Your Framed Cat Picture',
            'description': 'Simply upload your favourite cat image to see your new highly detailed and fully personalised framed cat picture spring to life! Each cat photo tile is handmade with a black square frame made of 50% recycled plastic.',
            'description2': 'Or choose an existing photo using our dedicated editing tool and see your new framed cat picture come to life! Your image is printed onto a wooden insert in full detail and vibrant colour.',
            'image': {
                'src': '/images/landingPages/uploadPhototile.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Style',
            'description': 'Easily flip and reposition your picture until your cat stands out on your brand-new personalised cat photo tile.\n ' +
                '\n' +
                'For best results, ensure that your image was taken from a good phone, tablet or digital camera. Position your cat to the centre of the photo tile and in good lighting for best results. Creating a fully personalised cat photo tile has never been easier than with us at Jigpaws. With endless creative possibilities, what are you waiting for?',
            'image': {
                'src': '/images/landingPages/photo-tiles/cat2.jpg',
                'alt': 'Stylise your image with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Cat Photo Tile To Your Door',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new and fully personalised pet photo tile with you within 2 - 6 working days. International shipping is also available. ',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-tiles/cat3.jpg',
                'alt': 'Jigpaws personalised gift photo tile with cat photo'
            }
        },
    },
    'personalised-photo-tiles': {
        'consistent': {
            'createLink': '/product/photo-tile',
            'price': 'photo-tile',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Personalised Photo Tiles Online | Jigpaws'
        }, 'meta': {
            'description': 'Design your own personalised photo wall tiles online with Jigpaws! Simply upload your photos and create lightweight, vibrant wall art that’s easy to mount and perfect for any room.',
            'og_url': 'https://www.jigpaws.com/gift-products/personalised-photo-tiles',
            'og_title': 'Create Personalised Photo Tiles Online | Jigpaws',
            'og_description': 'Design your own personalised photo wall tiles online with Jigpaws! Simply upload your photos and create lightweight, vibrant wall art that’s easy to mount and perfect for any room.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        },
        'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Photo Tiles with Jigpaws',
            'description': 'Welcome to Jigpaws, where creating your own personalised photo wall tiles is quick and hassle-free! Upload your favourite images, design your unique tiles, and enjoy stunning wall art in no time.\n\n' +
                'Once you\'ve designed your personalised photo tiles, you can enjoy free shipping across mainland UK, making it even easier to decorate your space or send a thoughtful gift!\n',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Design Your Personalised Photo Tiles',
            'description': 'Looking for a way to display your treasured memories, whether they’re of family, friends, or unforgettable events? Personalised photo tiles are the perfect solution!\n' +
                'Simply upload your chosen images, select from a variety of filters and customise the design to suit your style. Your photo tiles will arrive ready to mount, stylishly transforming your home or office with ease. They are perfect as a unique gift, too! \n',
            'image': {
                'src': '/images/landingPages/photo-tiles/generic1.webp',
                'alt': 'Jigpaws personalised gift photo tile with personalised photo'
            }
        }, 'panel_2': {
            'title': 'Edit Your Personalised Framed Photo',
            'description': 'Looking for a way to display your treasured memories, whether they’re of family, friends, or unforgettable events? Personalised photo tiles are the perfect solution!',
            'description2': '\'Simply upload your chosen images, select from a variety of filters and customise the design to suit your style. Your photo tiles will arrive ready to mount, stylishly transforming your home or office with ease. They are perfect as a unique gift, too!',
            'image': {
                'src': '/images/landingPages/uploadPhototile.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Lightweight, Durable, and Stylish',
            'description': 'Crafted from high-quality materials, our personalised photo wall tiles are designed to last while being easy to handle and mount. Each tile is:',
            'list': '• Printed in high-resolution colour for vibrant, true-to-life images.m\n' +
                '• Lightweight with a self-adhesive backing which can be restuck many times. No nails or tools are required.\n' +
                '• Available in one size: 20cm x 20cm.\n' +
                '• Made to suit any decor, whether you’re trying to create a modern or classic look.\n',
            'image': {
                'src': '/images/landingPages/photo-tiles/generic2.webp',
                'alt': 'Stylise your pet photo-tile'
            }
        }, 'panel_4': {
            'title': 'Order Your Personalised Photo Tiles Anywhere in the UK!\n',
            'description': 'Enjoy free mainland UK shipping on all orders, with delivery times ranging from 3 to 5 working days. For international customers, we offer affordable shipping options to help you share your memories with loved ones abroad.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-tiles/generic3.webp',
                'alt': 'Order your pet-phototile'
            }
        }, 'signOffSlider': {
            'title': 'Personalised Photobooks and Much More',
            'description': 'Here at Jigpaws, we don’t just create wonderful personalised photobooks for you to enjoy. We also create many other fully personalised gift products, so why not take a look through our range?',
            'list': '• Cards\n • Notebooks\n • Photo Tiles\n • Cushions\n • Jigsaws\n',
        },
        'faq': {
            "sectionTitle": "Personalised Photo Tiles FAQs",
            "items": [
                {
                    'title': 'What Are Personalised Photo Tiles?',
                    'description': 'Personalised photo tiles are lightweight wall art pieces featuring your photos, printed on durable perspex and scratchproof plastic and easy to mount without tools. Tiles are often placed together in a room to create stylish art collages.',
                },
                {
                    'title': 'How Do I Create Photo Tiles Online?',
                    'description': 'Creating photo tiles is simple with Jigpaws! Upload your images, customise your design using our online tool, and place your order. It’s that easy!',
                },
                {
                    'title': 'How Are Photo Tiles Mounted?',
                    'description': 'Each photo tile comes with a self-adhesive backing, allowing you to mount it easily without nails, screws, or tools. You can remove these pictures and replace them up to 5 times. \n' +
                        'The adhesive we use is wall-friendly, so hanging one of our photo tiles won’t damage your wall paint, wood panelling or tiling.',
                },
                {
                    'title': 'Can I Rearrange My Photo Tiles?',
                    'description': 'Yes, our tiles are designed to be repositionable, so you can adjust your display whenever you like.',
                },
                {
                    'title': 'Can I Use Photos From My Phone Or Social Media?',
                    'description': 'Absolutely! Upload images saved on your device, including those downloaded from social media platforms like Instagram or Facebook. You can even use your laptop, PC or tablet, too! Just ensure that your images are of a good quality for best results.',
                },
                {
                    'title': 'How Much Do Personalised Photo Tiles Cost?',
                    'description': 'Our photo tiles are competitively priced at £19 per 20cm tile.',
                },
                {
                    'title': 'Are Photo Tiles A Good Gift Idea?',
                    'description': 'Yes! Personalised photo tiles make a thoughtful gift for birthdays, anniversaries, or housewarmings, offering a unique way to display cherished memories. Since our photo tiles are easy to hang and move from wall to wall, they make the ideal gift for anyone looking to add a touch of style to their homes, without committing to a large poster-sized art piece. \n',
                },
                {
                    'title': 'What Materials Are Used For Photo Tiles?',
                    'description': 'Our tiles are made from lightweight, durable and perspex with a 50% recycled scratch-resistant matte finish to preserve your images for years. The photos themselves are printed in extraordinary high-quality photo paper, and printed in vibrant colour.',
                }, {
                    'title': 'How Long Does It Take To Receive My Photo Tiles?',
                    'description': 'Orders are typically delivered within 2 to 6 working days across mainland UK. International delivery times may vary.',
                },
            ]
        }
    },
    // SECTION PHOTOBOOKS
    'pet-photo-books': {
        'consistent': {
            'createLink': '/product/photobook/',
            'price': 'photobook-14x14',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Pet Photo Album | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create customisable pet photo books instantly with us at Jigpaws. Add a new piece to your home with free delivery available and with hundreds of items to choose from.',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-photo-books',
            'og_title': 'Create Your Pet Photo Album | Order Online | Jigpaws',
            'og_description': 'Create customisable pet photo books instantly with us at Jigpaws. Add a new piece to your home with free delivery available and with hundreds of items to choose from,',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Pet Photo Albums with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable pet photo book has never been simpler!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Pet Photo Album',
            'description': "Are you looking for a unique way to cherish your beloved pet? Or, are you looking for a way to honour your friend's pets for a special occasion? Now you can create fully personalised pet photo book albums with Jigpaws. \n" +
                '\n' +
                'Simply upload 20 images plus your favourite cover image to see your pet printed in high detail and with vibrant colours.',
            'image': {
                'src': '/images/landingPages/photo-books/pet1.jpg',
                'alt': 'Jigpaws personalised gift photobook with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Up To 20 Images\n',
            'description': 'Printed on a thick, 2.5mm hardcover, our pet photo albums are perfect for remembering a beloved pet or for showing off your most iconic pet pal! Our library-bound photo album books are perfect for a gift for any occasion \n' +
                '\n' +
                'Add style and flair with a range of filters, effects and much more to your personalised photo album, with our simple editing tool. Your images will then be printed on 170gsm satin-finished, sustainably sourced paper.',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadphotobook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Our library-bound pet photo albums are available in 2 sizes:',
            'list': '• 14x14cm\n' +
                '• 21x21cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/pet2.jpg',
                'alt': 'Choose your photobook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Pet Photo Album To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised pet photo book with you within 3 - 7 working days. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/pet3.jpg',
                'alt': 'Jigpaws personalised gift photbook with pet photo'
            }
        },
    },
    'dog-photo-books': {
        'consistent': {
            'createLink': '/product/photobook/',
            'price': 'photobook-14x14',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Dog Photo Album | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create customisable dog photo books instantly with us at Jigpaws. Add a new piece to your home with free delivery available and with hundreds of items to choose from.',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-photo-books',
            'og_title': 'Create Your Pet Photo Album | Order Online | Jigpaws',
            'og_description': 'Create customisable dog photo books instantly with us at Jigpaws. Add a new piece to your home with free delivery available and with hundreds of items to choose from.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Dog Photo Albums with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable dog photo album has never been simpler!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Dog Photo Album',
            'description': "Are you looking for a unique way to celebrate your or a loved one’s perfect pooch? Now you can create fully personalised dog photo albums or photo books with us here at Jigpaws.\n" +
                '\n' +
                'Simply upload 20 images plus your favourite cover image to see your dog printed in high detail and with vibrant colours.',
            'image': {
                'src': '/images/landingPages/photo-books/dog1.jpg',
                'alt': 'Jigpaws personalised gift photobook with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add 20 Unique Images',
            'description': 'Printed on a thick, 2.5mm hardcover, our dog photo books are perfect for remembering a beloved dog or for showing off your most iconic pet pal! Our library-bound dog photo album books are perfect for a gift for any occasion. \n' +
                '\n' +
                'Add style and flair with a range of filters, effects and much more to your new photo book, with our simple editing tool. Your images will then be printed on 170gsm satin-finished, sustainably sourced paper.',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadphotobook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Our library-bound dog photo albums are available in 2 sizes:',
            'list': '• 14x14cm\n' +
                '• 21x21cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/dog2.jpg',
                'alt': 'Choose your photobook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Dog Photo Album To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised dog photo book with you within 3 - 7 working days.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/dog3.jpg',
                'alt': 'Jigpaws personalised photo-book with dog photo'
            }
        },
    },
    'cat-photo-books': {
        'consistent': {
            'createLink': '/product/photobook/',
            'price': 'photobook-14x14',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Cat Photo Album | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat photo books instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from.',
            'og_url': 'https://www.jigpaws.com/gift-products/cat-photo-books',
            'og_title': 'Create Your Pet Photo Album | Order Online | Jigpaws',
            'og_description': 'Create personalised cat photo books instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Cat Photo Albums with Jigpaws',
            'description': 'Here at Jigpaws, making your own, fully personalised cat photo album has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Cat Photo Books',
            'description': "If you want to cherish the unique bond you share with your beloved feline friend, you can create a personalised cat photo album with Jigpaws. Capture all their quirky behaviours and distinctive personality traits on your phone or tablet, and every time you flip through your cat photo book, it will bring a smile to your face! \n" +
                '\n' +
                'At Jigpaws, we offer a sturdy, library-bound, hardback cat photo album containing 20 images of your feline friend in each of their moods, then order directly to your door (free shipping is available).',
            'image': {
                'src': '/images/landingPages/photo-books/cat1.jpg',
                'alt': 'Jigpaws personalised gift photobook with cat photo'
            }
        }, 'panel_2': {
            'title': 'Add 20 Images',
            'description': 'Browse through images of your favourite family friend with your brand new, hardback personalised cat photo album, today! Using our easy upload tool, all you need to do is simply add your images straight to your new cat photo book, and they will appear immediately. \n' +
                '\n' +
                'Then, add style and flair with a range of filters, effects and much more to your new cat photo album, using our simple editing tool. With hundreds of ways to purr-fect your personalised cat photo book, the decision is yours!',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadphotobook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws product is made by hand, meaning that we can offer 2 sizes of photo book:',
            'list': '• 14x14cm\n' +
                '• 21x21cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/cat2.jpg',
                'alt': 'Choose your photobook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Cat Photo Album',
            'description': 'With free shipping available to the mainland of the UK, Jigpaws can have your new, personalised cat photo book with you within 3 - 7 working days.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/cat3.jpg',
                'alt': 'Jigpaws personalised photo-book with cat photo'
            }
        },
    },
    'personalised-photo-books': {
        'consistent': {
            'createLink': '/product/photobook/',
            'price': 'photobook-14x14',
            'priceFrom': false,
        },
        'head': {
            'title': ' Create Personalised Photo Books | Jigpaws'
        }, 'meta': {
            'description': ' Create totally personalised photo books with us here online at Jigpaws! Simply upload your images to our site, and see your photo book come to life in vibrant colour and outstanding photo quality.',
            'og_url': 'https://www.jigpaws.com/gift-products/personalised-photo-books',
            'og_title': 'Create Personalised Photo Books | Jigpaws',
            'og_description': ' Create totally personalised photo books with us here online at Jigpaws! Simply upload your images to our site, and see your photo book come to life in vibrant colour and outstanding photo quality.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Photo Books with Jigpaws ',
            'description': 'Welcome to Jigpaws, where it’s simple to create your own fully personalised photo book with your choice of image!\n' +
                'Once you have completed your fully customisable and personalised photo books, you can ship them completely free to anywhere in mainland UK!\n',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Personalised Photo Book',
            'description': "If you are looking to create a personalised photo book to cherish family, friends, or even a wedding or birthday party, then you’re in the right place!" +
                '\n' +
                'All you need to do is upload your front cover, and then select 20 of your favourite images, and pick the pages on which you want them to appear! We will then save your new personalised photo book, and you can order it to anywhere in the world!',
            'image': {
                'src': '/images/landingPages/photo-books/generic1.webp',
                'alt': 'Jigpaws personalised gift photobook with personalised photo'
            }
        }, 'panel_2': {
            'title': 'Add Up To 20 Pages',
            'description': 'Printed on a thick, 2.5mm card hardcover, our unique photo books are ideal for remembering any special occasion you may have shared with your friends or family. We library bind every personalised photo book, allowing them to last a lifetime.\n' +
                '\n' +
                'Add style with a range of filters, effects and much more to your personalised photo book, with our easy-to-use editing tool. Your images will then be printed on 170gsm satin-finished, sustainably sourced paper on the interior.',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadphotobook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Size',
            'description': 'Our library-bound personalised photo books are available in 2 square sizes:',
            'list': '• 14x14cm\n' +
                '• 21x21cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/generic2.webp',
                'alt': 'Choose your photobook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Personalised Photo Book Anywhere in the UK!',
            'description': 'With free shipping available to anywhere in the mainland UK, you can order your completely personalised photo book to a mainland destination within 3 to 5 working days. We also offer international shipping options, too!',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/photo-books/generic3.webp',
                'alt': 'Jigpaws personalised photo-book with personalised photo'
            }
        }, 'signOffSlider': {
            'title': 'Personalised Photobooks and Much More',
            'description': 'Here at Jigpaws, we don’t just create wonderful personalised photobooks for you to enjoy. We also create many other fully personalised gift products, so why not take a look through our range?',
            'list': '• Cards\n • Notebooks\n • Photo Tiles\n • Cushions\n • Jigsaws\n',
        },
        'faq': {
            "sectionTitle": "Photbook FAQs",
            "items": [
                {
                    'title': 'What Is A Personalised Photo Book?',
                    'description': 'A Personalised Photo Book is a gift item which contains numerous pages of cherished memories or of family, friends or even pets! You can create a fully personalised photo book with Jigpaws online by using our simple online editing tool and order straight to your door.',
                },
                {
                    'title': 'How Do I Create A Photo Book Online?',
                    'description': 'Creating a photo book is easy with Jigpaws. Simply pick your cover, then choose 20 pictures from your device to fill the interior pages. From here, you can simply order it straight to your door, or to any mainland UK destination.',
                },
                {
                    'title': 'What Sizes Are Available For Photo Books?',
                    'description': 'Our photo books come in 2 square sizes, 14 cm by 14 cm, or 21 cm by 21 cm. Both sizes contain 20 interior pages.',
                },
                {
                    'title': 'How Long Does It Take To Make A Photo Book?',
                    'description': 'Using our simple online tool, it can take around 10 minutes to create a completely custom photo book. You can then order it straight to your door in as little as 3 working days.',
                },
                {
                    'title': 'Are There Custom Layout Options For Pages?',
                    'description': 'Yes, using our online tool, you can customise each page within your photo book, including image positions, and filters if you wish! The choice is yours.',
                },
                {
                    'title': 'Can I Use Photos From My Phone Or Social Media?',
                    'description': 'Yes, as long as you have the images saved to your device. You can download the images from your or someone else’s Facebook or Instagram account and add them to a memorable photo book easily online with Jigpaws.',
                },
                {
                    'title': 'How Much Does A Personalised Photo Book Cost?',
                    'description': 'Our photo books cost £22 for a 14 cm x 14 cm square cover and interior, or £25 for ones with a 21 cm x 21 cm square cover and interior.',
                },
                {
                    'title': 'Are Photo Books A Good Gift Idea?',
                    'description': 'Yes! Gifting personalised photo books to friends, family and colleagues always goes down a treat! Gifting someone a collection of memories shows them that you cherish the experiences that you both have shared. With free shipping available, ordering a photo book is also right on the money, too.',
                },
            ]
        }
    },
    // SECTION CARDS
    'pet-cards': {
        'consistent': {
            'createLink': '/product/greeting-card/',
            'price': 'matte-greeting-card',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Detailed Pet Cards | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create your own custom-made pet cards with us here at Jigpaws using our dedicated online tool. Simply pick your pet picture and see it on a card, instantly!',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-cards',
            'og_title': 'Create Detailed Pet Cards | Order Online | Jigpaws',
            'og_description': 'Create your own custom-made pet cards with us here at Jigpaws using our dedicated online tool. Simply pick your pet picture and see it on a card, instantly!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Unique Pet Cards with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable pet cards has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Pet Cards, Instantly!',
            'description': "What better way to show your pet-loving pal that you care than with a custom-made and totally unique pet card from us here at Jigpaws? Simply upload your image to our simple editing tool and see it instantly on a 170gsm card! \n" +
                '\n' +
                'Order 10 or more custom cards today to receive 30% off! ',
            'image': {
                'src': '/images/landingPages/cards/pet1.jpg',
                'alt': 'Jigpaws personalised card with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add an Image to Your Pet Cards',
            'description': 'Simply upload your image using your smartphone, laptop or tablet easily using our online tool and see it on a pet card, instantly.',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadCard.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Add Flair to Your Pet Cards',
            'description': 'You can see your new pet cards in real-time, and add filters to make your pet stand out from the page. You can also add emojis and annotations, too!\n' +
                'You can also now pick between a matte and gloss print. This makes your new pet card come alive with either a shiny and vibrant gloss finish or a stylish matte texture. \n' +
                'Your choice of finishes:\n',
            'list': '• Matte\n' +
                '• Gloss\n',
            'image': {
                'src': '/images/landingPages/cards/pet2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Pet Cards To Your Door',
            'description': 'With free shipping available to the mainland United Kingdom, Jigpaws can have your new, custom pet cards with you within 2 - 5 working days. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/cards/pet3.jpg',
                'alt': 'Jigpaws personalised card with pet photo'
            }
        },
    },
    'dog-cards': {
        'consistent': {
            'createLink': '/product/greeting-card/',
            'price': 'matte-greeting-card',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Personalised Dog Cards | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised dog cards with Jigpaw’s online editing tool. Quickly see your dog\'s picture on a card with many different editing options available.',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-cards',
            'og_title': 'Create Personalised Dog Cards | Order Online | Jigpaws',
            'og_description': 'Create personalised dog cards with Jigpaw’s online editing tool. Quickly see your dog\'s picture on a card with many different editing options available.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Unique Dog Cards with Jigpaws',
            'description': 'Welcome to Jigpaws, where designing your own, fully personalised dog cards has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Dog Cards Today',
            'description': "Are you looking for a unique way to cherish and remember your furry friend? Or do you want to show someone how much you care about them and their dog? Jigpaws lets you create fully personalised dog cards that perfectly accompany a unique gift. \n" +
                '\n' +
                'Simply upload your favourite dog image to see your brand-new cards in high detail and vibrant colour. Now with your choice of printing on glossy or matte 170gsm card! \n' +
                '\n' +
                'Order 10 or more unique cards today to receive 30% off! \n',

            'image': {
                'src': '/images/landingPages/cards/dog1.jpg',
                'alt': 'Jigpaws personalised card with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add A Picture to Your Dog Card\n',
            'description': 'Uploading your image to Jigpaws is simple and quick. You can see your new dog cards in real-time and add filters to enhance their colour. Apply sepia tones or create a stark black-and-white effect instantly, or add annotations and even emojis! Make the perfect accompaniment to any gift (or even keep one for yourself).\n',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadCard.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Our dog cards are printed on high-quality, 170gsm card and are available with your choice of finishes. \n' +
                'Your card can be finished in either:\n',
            'list': '• Matte\n' +
                '• Gloss\n',
            'image': {
                'src': '/images/landingPages/cards/dog2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Dog Cards To Your Door',
            'description': 'Free shipping is available for all our dog cards in 2 - 5 working days to all mainland UK destinations. International shipping is also available. \n',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/cards/dog3.jpg',
                'alt': 'Jigpaws personalised card with dog photo'
            }
        },
    },
    'cat-cards': {
        'consistent': {
            'createLink': '/product/greeting-card/',
            'price': 'matte-greeting-card',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Unique Cat Cards | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat cards instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from.',
            'og_url': 'https://www.jigpaws.com/gift-products/cat-cards',
            'og_title': 'Create Unique Cat Cards | Order Online | Jigpaws',
            'og_description': 'Create personalised cat cards instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Custom Cat Cards with Jigpaws',
            'description': 'Here at Jigpaws, making and printing your own personalised cat cards has never been easier!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Cat Cards',
            'description': "Looking for a special way to remember your fuzzy friend or show someone how much you care about them and their cat? Jigpaws offers fully customed and personalised cat cards that are perfect as a unique gift. Purr-fect for birthdays, anniversaries or any occasion, creating your own unique cat cards has never been easier. \n" +
                '\n' +
                'Simply upload your favourite cat image to see your brand-new cards in high detail, vibrant colour and printed on your choice of glossy or matte finish.',

            'image': {
                'src': '/images/landingPages/cards/cat1.jpg',
                'alt': 'Jigpaws personalised card with cat photo'
            }
        }, 'panel_2': {
            'title': 'Add An Image To Your Cat Cards',
            'description': 'Your cat card will be printed on a high-quality 170gsm card and is available with your choice of finishes.\n' +
                '\n' +
                'Now with easy options to flip and reposition your image, you can adjust your image until it looks precisely how you want it.',

            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadCard.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Your cat card will be printed on a high-quality 170gsm card and is available with your choice of finishes.\n',
            'list': '• Gloss Finish\n' +
                '• Matte Finish\n',
            'image': {
                'src': '/images/landingPages/cards/cat2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Cat Cards To Your Door',
            'description': 'Free shipping is available on our entire range of cat cards within 2 - 5 working days to the mainland UK. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/cards/cat3.jpg',
                'alt': 'Jigpaws personalised card with cat photo'
            }
        },
    },
    'personalised-cards': {
        'consistent': {
            'createLink': '/product/greeting-card/',
            'price': 'matte-greeting-card',
            'priceFrom': false,
        },
        'head': {
            'title': 'Personalised Photo Cards | Jigpaws',
        }, 'meta': {
            'description': 'At Jigpaws, it’s easy to create one-of-a-kind personalised cards with your own photos. Start creating your personalised photo cards online today!',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-cards',
            'og_title': 'Personalised Photo Cards | Jigpaws',
            'og_description': 'At Jigpaws, it’s easy to create one-of-a-kind personalised cards with your own photos. Start creating your personalised photo cards online today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Photo Cards with Jigpaws',
            'description': 'Welcome to Jigpaws, where it’s simple to create one-of-a-kind personalised photo cards for any occasion. Whether it’s a birthday card, a thank you card or a holiday card, make it personal with Jigpaws!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Personalised Photo Cards Today!',
            'description': 'Are you looking for a unique way to celebrate your friends and family’s big moments? How better than with a high-quality totally personalised photo card? Jigpaws lets you create fully personalised photo cards for any occasion with just a few clicks. \n' +
                '\n' +
                'Simply upload your favourite photo into our easy-to-use system. You can even add text, filters and even emojis to make it extra special. Our system works in real-time, meaning that you can preview your creation at every step of the journey in high detail and vibrant colour. \n' +
                '\n' +
                'Order 10 or more personalised cards to receive 30% off!\n',

            'image': {
                'src': '/images/landingPages/cards/generic1.webp',
                'alt': 'Jigpaws personalised card'
            }
        }, 'panel_2': {
            'title': 'AUpload Your Photo to Get Started',
            'description': 'Creating personalised photo cards couldn’t be easier than with Jigpaws. Simply upload your chosen image and edit it to match your vibe. Choose between image filters like sepia and black and white, text additions, and even emojis! It couldn’t be simpler to make your loved ones feel extra special!',
            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadCard.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print ',
            'description': 'Our personalised cards are printed on high-quality 170gsm card stock and are available in your choice of finish. Choose between:\n',
            'list': '• Matte\n' +
                '• Gloss\n',
            'image': {
                'src': '/images/landingPages/cards/generic2.webp',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Personalised Photo Cards to Your Door',
            'description': 'We offer free shipping on all of our personalised cards. You can expect to receive your order in 2-5 working days to all mainland UK destinations. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/cards/generic3.webp',
                'alt': 'Jigpaws personalised card with pet photo'
            }

        }, 'signOffSlider': {
            'title': 'Personalised Cards and Much More',
            'description': 'Here at Jigpaws, we don’t just create wonderful personalised cards for you to enjoy. We also create many other fully personalised gift products, so why not take a look through our range?',
            'list': '• Notebooks\n • Photo Tiles\n • Cushions\n• Photo Books\n • Jigsaws\n',
        },
        'faq': {
            "sectionTitle": "Personalised Cards FAQs",
            "items": [
                {
                    'title': 'What is a Personalised Photo Card?',
                    'description': 'Personalised photo cards are greetings cards that use your own photos for a special personal touch. They make great gifts for birthdays, Christmas or any occasion where you want your loved ones to know you’re thinking of them.',
                },
                {
                    'title': 'What Image Can I Upload?',
                    'description': 'Your options are endless when it comes to uploading photos for personalised cards. However, it’s best to choose an image with high resolution so that it is guaranteed to look good when printed. Here are some ideas to give you some photo inspiration:\n\n' +
                        '• A photo of you and your friend/loved one for a birthday card \n\n' +
                        '• A photo of the whole family for a personalised Christmas card\n\n' +
                        '• A photo of your team/colleagues for a leaving card',
                },
                {
                    'title': 'Can I Create a Photo Card for Any Occasion?',
                    'description': 'Yes! That’s the beauty of personalised cards - you can create them for any occasion and situation. You simply choose your favourite photo, choose the overlaying text and add any effects to match your vibe.',
                },
                {
                    'title': 'What File Type and Size Can I Upload?',
                    'description': 'You will get better results with a higher-quality image, however, you can upload any image size or format that you wish. Our clever online tool converts your image into a high-quality and vibrant image that appears on your card, instantly.',
                },
                {
                    'title': 'What are the Card Shapes and Sizes?',
                    'description': 'Our cards come in 14 x 14cm squares with a blank interior, perfect for handwritten messages from you to a loved one.',
                },
            ]
        },
    },
    // SECTION NOTEBOOKS
    'pet-notebooks': {
        'consistent': {
            'createLink': '/product/journal/',
            'price': 'lined-journal',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Pet Notebook | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create your own personalised pet notebooks with us here at Jigpaws by using our dedicated online tool. Simply pick your pet picture and see it on a notebook, instantly!',
            'og_url': 'https://www.jigpaws.com/gift-products/pet-notebooks',
            'og_title': 'Create Your Pet Notebook | Order Online | Jigpaws',
            'og_description': 'Create your own personalised pet notebooks with us here at Jigpaws by using our dedicated online tool. Simply pick your pet picture and see it on a notebook, instantly!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Pet Notebooks with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable pet notebook has never been easier!',
            // 'description2': 'Simply upload your favourite pet image to see your brand-new notebook in high detail, vibrant colours and always printed on a glossy, hardback cover. '
        }, 'panel_1': {
            'title': 'Create Your Pet Notebook',
            'description': "Are you looking for a unique way to cherish your or someone else’s beloved pet? Or, are you looking for a great way to give someone an extra special gift featuring their pet pal? Now you can create a fully personalised pet notebook with Jigpaws.\n" +
                '\n' +
                'Simply upload your favourite pet image to see your brand-new notebook in high detail, vibrant colours and always printed on a glossy, hardback cover.',

            'image': {
                'src': '/images/landingPages/notebooks/pet1.jpg',
                'alt': 'Jigpaws personalised card with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Uploading your image to Jigpaws is a straightforward and secure process. You can see your new pet notebook in real-time and add filters to enhance its vibrancy, apply sepia tones or create a stark black-and-white effect.\n' +
                '\n' +
                'With easy flip and reposition options, you can adjust your image until your pet image really stands out on your notebook.',

            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadNotebook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Our library-bound notebooks are available with your choice of paper style on the inside. Each comes with 64 interior pages of your choice:\n',
            'list': '• Lined Paper\n' +
                '• Graph Paper \n' +
                '• Plain White Paper\n',

            'image': {
                'src': '/images/landingPages/notebooks/pet2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Pet Notebook To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, custom pet notebook with you within 2 - 5 working days. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/notebooks/pet3.jpg',
                'alt': 'Jigpaws personalised notebook with pet photo'
            }
        },
    },
    'dog-notebooks': {
        'consistent': {
            'createLink': '/product/journal/',
            'price': 'lined-journal',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Your Dog Notebook | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised dog notebooks with Jigpaws\' online editing tool. Quickly see your pet\'s picture on a notebook with lots of different editing options available.',
            'og_url': 'https://www.jigpaws.com/gift-products/dog-notebooks',
            'og_title': 'Create Your Dog Notebook | Order Online | Jigpaws',
            'og_description': 'Create personalised dog notebooks with Jigpaws\' online editing tool. Quickly see your pet\'s picture on a notebook with lots of different editing options available.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Dog Notebooks with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully customisable dog notebook has never been easier!',
            // 'description2': 'Simply upload your favourite pet image to see your brand-new notebook in high detail, vibrant colours and always printed on a glossy, hardback cover. '
        }, 'panel_1': {
            'title': 'Create Your Dog Notebook',
            'description': "Are you looking for a special way to remember your loyal companion, create a keepsake for someone special or pay homage to a furry friend? Jigpaws offers fully personalised dog notebooks that are perfect as a unique gift. Ideal for study, work or jotting down ideas, our dog notepads are a real treat for any dog lover. \n" +
                '\n' +
                'Simply upload your favourite dog image to see your brand-new notebook in high detail, vibrant colours and printed on a glossy, hardback cover. \n',

            'image': {
                'src': '/images/landingPages/notebooks/dog1.jpg',
                'alt': 'Jigpaws personalised card with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Uploading your image to Jigpaws is a straightforward and secure process. You can see your new dog notebook in real-time and add filters to enhance its vibrancy, apply sepia tones or create a stark black-and-white effect.\n' +
                '\n' +
                'With easy flip and reposition options, you can adjust your image until your dog is bounding off the page!',

            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadNotebook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Our library-bound notebooks are available with your choice of printed paper interiors. Each comes with 64 pages of your choice of:\n',
            'list': '• Lined Paper\n' +
                '• Graph Paper \n' +
                '• Plain White Paper\n',

            'image': {
                'src': '/images/landingPages/notebooks/dog2.jpg',
                'alt': 'Choose your card with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Dog Notebook To Your Door',
            'description': 'Free shipping is available for all dog notebooks within 2 - 5 working days to mainland UK. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/notebooks/dog3.jpg',
                'alt': 'Jigpaws personalised notebook with dog photo'
            }
        },
    },
    'cat-notebooks': {
        'consistent': {
            'createLink': '/product/journal/',
            'price': 'lined-journal',
            'priceFrom': false,
        },
        'head': {
            'title': 'Create Cat Notebooks | Order Online | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat notebooks instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from. \n',
            'og_url': 'https://www.jigpaws.com/gift-products/cat-notebooks',
            'og_title': 'Create Your Cat Notebook | Order Online | Jigpaws',
            'og_description': 'Create personalised cat notebooks instantly with Jigpaws. Add to your kitty collection with free delivery available and many different items to choose from. \n',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create a Custom Cat Notebook with Jigpaws',
            'description': 'Here at Jigpaws, making and printing your own personalised cat notebook has never been easier!',
            // 'description2': 'Simply upload your favourite pet image to see your brand-new notebook in high detail, vibrant colours and always printed on a glossy, hardback cover. '
        }, 'panel_1': {
            'title': 'Create Your Cat Notebook',
            'description': "Looking for a special way to remember your cuddly friend or show someone how much you care about their cat? Jigpaws offers fully personalised cat notebooks that are perfect as a unique gift. Ideal for work, study or for jotting down ideas, our cat notepads are a real treat for any cat lover.\n" +
                '\n' +
                'Simply upload your favourite cat image to see your brand-new notebook in high detail, vibrant colour and printed on a glossy, hardback cover.\n',

            'image': {
                'src': '/images/landingPages/notebooks/cat1.jpg',
                'alt': 'Jigpaws personalised notebook with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Uploading an image to your cat notebook on Jigpaws is a simple, straightforward and secure process. You can see your new cat notebook in real-time and add filters to enhance its vibrancy, apply sepia tones or create a stark black-and-white, contrasted effect.\n' +
                '\n' +
                'Now with easy options to flip and reposition your image, you can adjust your image until your new cat notebook springs to life!',

            // 'description2': 'Choose an existing photo in our editing tool and see your new personalised pet picture frame come alive. Your image is printed onto a wooden insert in full detail and vibrant colour. ',
            'image': {
                'src': '/images/landingPages/uploadNotebook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'Our library-bound notebooks are available with your preferred interior paper style. Each comes with 64 pages of your choice of:\n',
            'list': '• Lined Paper\n' +
                '• Graph Paper \n' +
                '• Plain White Paper\n',

            'image': {
                'src': '/images/landingPages/notebooks/cat2.jpg',
                'alt': 'Choose your notebook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Cat Notebook To Your Door',
            'description': 'Free shipping is available for all cat notebooks within 2 - 5 working days to the UK mainland. International shipping is also available.',
            // 'description2': '• 30 x 30cm\n • 46 x 46cm\n',
            // 'list': '• 30 x 30cm\n • 46 x 46cm\n',
            'image': {
                'src': '/images/landingPages/notebooks/cat3.jpg',
                'alt': 'Jigpaws personalised notebook with cat photo'
            }
        },
    },
    'personalised-notebooks': {
        'consistent': {
            'createLink': '/product/journal',
            'price': 'lined-journal',
            'priceFrom': 'false',
        },
        'head': {
            'title': 'Personalised Photo Notebooks | Jigpaws'
        }, 'meta': {
            'description': 'At Jigpaws, it’s easy to create your own personalised photo notebook with our online tool and free delivery. Start creating your photo notebook today!',
            'og_url': ' https://www.jigpaws.com/gift-products/personalised-jigsaws',
            'og_title': 'Personalised Photo Notebooks | Jigpaws',
            'og_description': 'At Jigpaws, it’s easy to create your own personalised photo notebook with our online tool and free delivery. Start creating your photo notebook today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Photo Notebooks with Jigpaws',
            'description': 'Welcome to Jigpaws, where it’s simple to create your own fully personalised notebook with your choice of image!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create Your Photo Notebook',
            'description': 'If you are looking for the perfect solution for a loved one’s birthday, or if you are looking to celebrate a cherished memory, then why not create a personalised jigsaw with Jigpaws?\n\n' +
                'All you need to do is upload your special image - whether it’s a picture of you and your friends, your whole family on your latest holiday or even your treasured pet. Then wait for your brand-new fully-personalised notebook to be delivered to your door.',
            'image': {
                'src': '/images/landingPages/notebooks/generic1.webp',
                'alt': 'Jigpaws personalised gift notebook'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Uploading your image for your photo notebook is a straightforward process. Once you’ve selected and uploaded your picture, you can see exactly what your personalised notebook will look like. You can even add filters to enhance the vibrancy of your image or transform it into a stunning old-style photo with sepia tones.',
            'description2': 'It’s just as simple to flip, crop or reposition your image, so you can be sure your new photo notebook will look exactly the way you want it to!',
            'image': {
                'src': '/images/landingPages/uploadNotebook.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Choose Your Paper Print',
            'description': 'All our library-bound notebooks are printed on a glossy, hardback cover and come with your choice of interior paper style. Each comes with 64 pages of your choice of:\n',
            'list': '• Lined Paper\n • Graph Paper\n • Plain White Paper\n',
            'image': {
                'src': '/images/landingPages/notebooks/generic3.webp',
                'alt': 'choose your notebook with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Personalised Photo Notebook To Your Door',
            'description': 'Our personalised photo notebooks all include free shipping to the UK mainland. Your customised notebook will arrive within 2 - 5 working days. International shipping is also available..',
            'image': {
                'src': '/images/landingPages/notebooks/generic2.webp',
                'alt': 'Jigpaws personalised gift notebook'
            }
        }, 'signOffSlider': {
            'title': 'Personalised Notebooks and Much More',
            'description': 'Here at Jigpaws, we don’t just create wonderful personalised notebooks  for you to enjoy. We also create many other fully personalised gift products, so why not take a look through our range?',
            'list': '• Cards\n • Photo Tiles\n • Cushions\n• Photo Books\n• Jigsaws\n',
        },
        'faq': {
            "sectionTitle": "Notebook FAQs",
            "items": [
                {
                    'title': 'What Is a Photo Notebook?',
                    'description': 'A photo notebook can be personalised with your choice of photo to make it extra special. It can be used for taking notes or as a diary and is perfect for schools, work, or just for jotting down your shopping list! A photo notebook also makes an ideal personalised gift.\n\n' +
                        'At Jigpaws, we offer a range of high-quality photo notebooks that can be fully personalised with your choice of image.\n',
                },
                {
                    'title': 'What Image Can I Upload?',
                    'description': 'When it comes to choosing an image for your personalised notebook, the options are almost endless. Here are some ideas to give you some inspiration:',
                    'list': '• Cards\n • Photo Tiles\n • Cushions\n• Photo Books\n• Jigsaws\n',

                },
                {
                    'title': 'What Is the Difference Between a Photo Notebook and a Photo Book?',
                    'description': 'A photo book is a personalised book that features your choice of photographs on both the front cover and inside. It’s a modern alternative to a photo album. However, a photo notebook features your choice of image on the front cover only and the inside is like a normal notebook with either lined, graph or plain paper. \n\n' +
                        'At Jigpaws, we offer both personalised photo books and photo notebooks for you to choose from. If you’re looking for a beautiful way to display your favourite photographs, a photo book is the perfect choice. However, if you want a functional notebook that’s personalised with your choice of photo to make it extra special, a personalised photo notebook is the right way to go!\n',
                },
            ]
        }
    },
    // SECTION CUSHIONS
    'pet-cushions': {
        'consistent': {
            'createLink': '/product/cushion',
            'price': 'suede-cushion-12x12',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Pet Cushions | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised pet cushions  with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_url': 'https://www.jigpaws.com//gift-products/pet-cushions',
            'og_title': 'Personalised Pet Cushions | Create | Jigpaws',
            'og_description': 'Create personalised pet cushions with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Pet Cushions with Jigpaws',
            'description': 'Welcome to Jigpaws, where making your own, fully personalised pet cushion has never been simpler!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Pet Cushions',
            'description': 'Are you looking for a unique way to cherish your beloved pet? Now you can create fully personalised pet cushions with us here at Jigpaws. From a happy hamster to a legendary lizard, simply upload your image to Jigpaws and you can see your new extra comfy cushion, instantly!',
            'image': {
                'src': '/images/landingPages/cushions/pet1.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Get ready to unwind with your new fully personalised pet cushion, today! Using our simple upload tool, all you need to do is simply add an image to see it immediately on your new cushion.',
            'description2': ' Add style and flair with a range of filters, effects and much more to your new cushion, with our simple editing tool. With hundreds of ways to perfect your personalised pet cushion, the decision is yours!',
            'image': {
                'src': '/images/landingPages/uploadCushion.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws product is made by hand, meaning that we can offer 2 sizes of cushion:',
            'list': '• 30x30cm\n' +
                '• 46x46cm \n',
            'image': {
                'src': '/images/landingPages/cushions/pet2.jpg',
                'alt': 'choose your cushion with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised pet cushion with you within 3 - 7 working days.',
            'image': {
                'src': '/images/landingPages/cushions/pet3' +
                    '.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet photo'
            }
        },
    }, 'dog-cushions': {
        'consistent': {
            'createLink': '/product/cushion',
            'price': 'suede-cushion-12x12',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Dog Cushions | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised dog cushions  with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_url': 'https://www.jigpaws.com//gift-products/dog-cushions',
            'og_title': 'Personalised Pet Cushions | Create | Jigpaws',
            'og_description': 'Create personalised dog cushions with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Pet Cushions with Jigpaws',
            'description': 'Welcome to Jigpaws, where you can create your own personalised dog cushion in minutes!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Pet Cushions',
            'description': 'Do you love your furry friends and want to show them off in style? Our cushions are made with soft and cosy faux-suede covers that you can customise with any dog photo you want! Whether it’s to celebrate a new puppy or a loyal companion, just upload your photo to Jigpaws and you can see your new snuggly cushion, instantly.',
            'image': {
                'src': '/images/landingPages/cushions/dog1.jpg',
                'alt': 'Jigpaws personalised gift cushion with dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Get ready to relax with your new personalised dog cushion, today! Using our simple upload tool, all you need to do is add your dog image and it will appear immediately on your new cushion.',
            'description2': 'Add style and flair with a range of filters, effects and much more to your new cushion, with our simple editing tool. With hundreds of ways to perfect your personalised dog cushion, the decision is completely up to you!',
            'image': {
                'src': '/images/landingPages/uploadCushion.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws product is made by hand, meaning that we can offer 2 sizes of cushion:',
            'list': '• 30x30cm\n' +
                '• 46x46cm \n',
            'image': {
                'src': '/images/landingPages/cushions/pet2.jpg',
                'alt': 'choose your cushion with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised dog cushion with you within 3 - 7 working days.',
            'image': {
                'src': '/images/landingPages/cushions/pet3' +
                    '.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet photo'
            }
        },
    },
    'cat-cushions': {
        'consistent': {
            'createLink': '/product/cushion',
            'price': 'suede-cushion-12x12',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Cat Cushions | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat cushions  with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_url': 'https://www.jigpaws.com//gift-products/cat-cushions',
            'og_title': 'Personalised Cat Cushions | Create | Jigpaws',
            'og_description': 'Create personalised cat cushions with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Cat Cushions with Jigpaws',
            'description': 'Welcome to Jigpaws, where you can create your own personalised dog cushion in minutes!',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Cat Cushions',
            'description': 'Do you adore your purring pal and want to pamper them in style? Our cushions are made with soft and cosy faux-suede covers that you can customise with your favourite picture of your beloved cat! Whether it’s to celebrate a new kitten or an old, faithful friend, just upload your photo to Jigpaws and you can see your new cuddly cushion, instantly!',
            'image': {
                'src': '/images/landingPages/cushions/cat1.jpg',
                'alt': 'Jigpaws personalised gift cushion with cat photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Get ready for a cozie cat nap with your new personalised cat cushion, today! Using our easy upload tool, all you need to do is simply add an image and it will appear on  your new cushion mmediately.',
            'description2': 'Add style and flair with a range of filters, effects and much more to your new cushion, with our simple editing tool. With hundreds of ways to perfect your personalised cat cushion, the decision is yours!',
            'image': {
                'src': '/images/landingPages/uploadCushion.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws product is made by hand, meaning that we can offer 2 sizes of cushion:',
            'list': '• 30x30cm\n' +
                '• 46x46cm \n',
            'image': {
                'src': '/images/landingPages/cushions/cat2.jpg',
                'alt': 'choose your cushion with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised cat cushion with you within 3-5 working days.',
            'image': {
                'src': '/images/landingPages/cushions/cat3.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet photo'
            }
        },
    },
    'personalised-cushions': {
        'consistent': {
            'createLink': '/product/cushion',
            'price': 'suede-cushion-12x12',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Create Personalised Cushions | Custom Cushions | Jigpaws'
        }, 'meta': {
            'description': 'Create and personalise your own unique custom cushions with us here at Jigpaws. You can order online and receive free shipping with every order.',
            'og_url': 'https://www.jigpaws.com//gift-products/personalised-cushions',
            'og_title': 'Create Personalised Cushions | Custom Cushions | Jigpaws',
            'og_description': 'Create and personalise your own unique custom cushions with us here at Jigpaws. You can order online and receive free shipping with every order',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**  Order by the 17th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 8th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Cushions with Jigpaws',
            'description': 'At Jigpaws, creating personalised cushions is easier than ever before. Create your custom cushion, and order it right to your door, with free UK mainland shipping available.',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Create A Personalised Cushion in a Few Simple Steps',
            'description': 'Making a custom cushion is a great gift for a birthday or special occasion to decorate your home, or to show someone you care. \n\n With Jigaws, creating a high-quality personalised cushion using your own picture is quick, simple and secure!',

            'image': {
                'src': '/images/landingPages/cushions/generic1.webp',
                'alt': 'Jigpaws personalised gift cushion'
            }
        }, 'panel_2': {
            'title': 'Simply Add An Image',
            'description': 'Using our online tool, you can upload a picture from your phone, computer or tablet in a few simple clicks. ',
            'description2': 'You can add effects and filters to your picture, or crop the image to get it exactly how you want it to look. You’ll then be able to see your brand-new, custom cushion come to life!',
            'image': {
                'src': '/images/landingPages/uploadCushion.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Your new, fully custom cushion will be printed with a removable faux-suede cover and come complete with a plush interior. \n\n Our custom cushions come in sizes 30 x 30cm or 46 x 46cm, and each is machine washable.',
            'image': {
                'src': '/images/landingPages/cushions/generic2.webp',
                'alt': 'choose your cushion with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'Once you have your personalised photo cushion just right, you can order it to your door, with free shipping available to mainland UK destinations. Please allow 3 - 7 working days for your cushion to arrive. ',
            'image': {
                'src': '/images/landingPages/cushions/generic3.webp',
                'alt': 'Jigpaws personalised gift cushion',
            }
        }, 'signOffSlider': {
            'title': 'Create Custom Cushions And Much, Much More!',
            'description': 'Here at Jigpaws, we don’t just create stylish cushions for you to get comfy with, but we also make Personalised Jigsaws, Custom Notebooks, and so much more! Why not view the rest of our range with Jigpaws?  ',
            'list': '• Cards\n • Notebooks\n • Photo Tiles\n • Photo Books\n  • Jigsaws\n',
        },
        'faq': {
            "sectionTitle": "Personalised Cushion FAQs",
            "items": [
                {
                    'title': 'What Is A Personalised Cushion?',
                    'description': 'A personalised cushion is a custom-made cushion featuring your chosen image, design, or text, perfect for gifts or to  stylishly decorate your home.',
                },
                {
                    'title': 'How Do I Create A Personalised Cushion Online?',
                    'description': 'Simply upload your image using Jigpaws’ online tool, customise it with filters or effects, and place your order securely in just a few steps. Feel free to give many good-quality images a go to see which looks best on your brand-new cushion, before ordering. It really is that simple!',
                },
                {
                    'title': 'What Sizes Are Available For Personalised Cushions?',
                    'description': 'Jigpaws offers two sizes: 30 x 30cm and 46 x 46cm, both featuring a removable faux-suede cover and plush interior.',
                },
                {
                    'title': 'Are Personalised Cushions Machine Washable?',
                    'description': 'Yes, the removable faux-suede covers are machine washable, making it easy to keep your cushions clean and fresh. We recommend washing your cushion covers inside out, and on a cool wash to help preserve your image.',
                },
                {
                    'title': 'How Long Does It Take To Receive My Custom Cushion?',
                    'description': 'Orders are typically delivered within 3 to 5 working days for mainland UK destinations. International shipping times will vary depending on your location.',
                },
                {
                    'title': 'Can I Use Photos From My Phone Or Social Media For My Cushion?',
                    'description': 'Yes, you can upload photos directly from your phone, computer, or tablet. Social media images can also be used if saved to your device. For best results, be sure to use high-quality images which were taken in good lighting!',
                },
                {
                    'title': 'What Materials Are Used For Personalised Cushions?',
                    'description': 'Our cushions feature a removable faux-suede cover and a plush, soft interior for comfort and durability.',
                },
                {
                    'title': 'How Much Does A Personalised Cushion Cost?',
                    'description': 'Personalised cushions start from £26, with free shipping available to mainland UK destinations.',
                },
                {
                    'title': 'Are Personalised Cushions A Good Gift Idea?',
                    'description': 'Absolutely! Personalised cushions make thoughtful and unique gifts for birthdays, anniversaries, and other special occasions.  They also make a great gift your yourself, or if you want to add a touch of personal charm to a sofa, armchair or office space!',
                },
                {
                    'title': 'Is Free Shipping Available For Personalised Cushions?',
                    'description': 'Yes, Jigpaws offers free shipping on all personalised cushions to mainland UK addresses.  International shipping is also available, however, your delivery times may vary, depending on location.',
                },
            ]
        },
    },

    //SECTION JIGSAWS
    'pet-jigsaws': {
        'consistent': {
            'createLink': '/product/jigsaw',
            'price': 'jigsaw-500-50x38',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Pet Jigsaw Puzzles | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised pet jigsaw puzzles with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_url': 'https://www.jigpaws.com//gift-products/pet-jigsaws"',
            'og_title': 'Personalised Pet Jigsaw Puzzles | Create | Jigpaws',
            'og_description': 'Create personalised pet jigsaw puzzles with us here at Jigpaws with options to customise your Jigsaws and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**   Order by the 20th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 13th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Pet Jigsaw Puzzles with Jigpaws',
            'description': 'Welcome to Jigpaws, where creating your own fully personalised pet jigsaw puzzle has never been easier.',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'PERSONALISED Pet JIGSAWS',
            'description': 'Are you looking for a unique way to enjoy your favourite pet photos? Why not create a personalised jigsaw puzzle? With options for either 500 or 1000 pieces, this puzzle is perfect for those seeking a challenge and casual puzzlers alike. It also comes in a beautiful presentation box - making it a great treat for yourself or as a gift for family and friends.',
            'image': {
                'src': '/images/landingPages/jigsaws/pet1.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'To create a fully personalised pet jigsaw, simply upload a high-quality photo, which can either be taken from a tablet, smartphone or digital camera.\n',
            'description2': 'Easily add style and flair with a range of filters, effects and much more to your new personalised pet jigsaw, with our simple editing tool.',
            'image': {
                'src': '/images/landingPages/uploadJigsaw.jpg',
                'alt': 'Upload photos with Jigpaws'
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws puzzle is made by hand, meaning that we can offer 2 sizes:',
            'list': '• 500 pieces (50x38cm)\n' +
                '• 1000 pieces (66x50cm)\n',
            'image': {
                'src': '/images/landingPages/jigsaws/pet2.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet photo'
            }
        }, 'panel_4': {
            'title': 'Order To Your Door',
            'description': 'With free shipping available to mainland UK, Jigpaws can have your new, personalised pet jigsaw puzzle with you within 3-5 working days.',
            // 'description2': 'They can be ordered with your option of:',
            'image': {
                'src': '/images/landingPages/jigsaws/pet3.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet photo'
            }
        }, 'panel_5': {
            'title': 'Stylise And Add Flair',
            'description': 'Add stickers, effects, annotations and much more to your new jigsaw, or just keep the image blank if you prefer. With hundreds of ways to create your personalised pet jigsaw puzzle, the decision is yours!',
            'image': {
                'src': '/images/landingPages/stylise.jpg',
                'alt': 'Stylise your image with Jigpaws'
            }
        }, 'panel_6': {
            'title': 'Should there be a final CTA',
            'description': 'here?',
            // 'description2': 'At Jigpaws, we offer free shipping on all our cards, it ' + 'has never been easier to get strong, detailed and fully ' + 'personalised cat cards delivered straight to your door!',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-card.jpg',
                'alt': 'Jigpaws personalised gift photo card with pet cat photo'
            }
        },
    },
    'dog-jigsaws': {
        'consistent': {
            'createLink': '/product/jigsaw',
            'price': 'jigsaw-500-50x38',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Dog Jigsaw Puzzle | Create | Jigpaws'
        }, 'meta': {
            'description': 'Creating your own Personalised Dog Jigsaw Puzzle has never been easier than with Jigpaws. Simply upload your image, stylise and we ship it straight to your door!',
            'og_url': 'https://www.jigpaws.com//gift-products/dog-jigsaws"',
            'og_title': 'Personalised Dog Jigsaw Puzzle | Create | Jigpaws',
            'og_description': 'Creating your own Personalised Dog Jigsaw Puzzle has never been easier than with Jigpaws. Simply upload your image, stylise and we ship it straight to your door!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**   Order by the 20th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 13th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Dog Jigsaw Puzzles with Jigpaws',
            'description': 'Welcome to Jigpaws, a place where creating your own, fully customisable and personalised dog jigsaw puzzle has never been easier!\n',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'PERSONALISED DOG JIGSAWS',
            'description': 'Looking for a unique way to enjoy your favourite dog photo? Then why not create a personalised jigsaw puzzle? With options for either 500 or 1000 pieces, this puzzle is perfect for both casual puzzlers and those seeking a challenge. Plus, it comes in a beautiful presentation box, making it a great gift for family and friends or a special treat for yourself!',
            'image': {
                'src': '/images/landingPages/jigsaws/dog1.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'To create a personalised dog jigsaw puzzle, simply upload a high-quality photo of your or someone else’s beloved pooch, which can be taken from a smartphone, tablet or digital camera. We will upload your image to your jigsaw in vibrant colour. ',
            'description2': 'Add style and flair with a range of filters, effects and much more to your new jigsaw, with our simple editing tool. With hundreds of ways to perfect your personalised dog jigsaw puzzle, the decision is yours!\n',
            'image': {
                'src': '/images/landingPages/uploadJigsaw.jpg',
                'alt': 'Upload a photo with Jigpaws'
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each Jigpaws puzzle is made by hand and is available in 2 sizes:\n',
            'list': '• 500 pieces (50x38cm)\n' +
                '• 1000 pieces (66x50cm)\n',
            'image': {
                'src': '/images/landingPages/jigsaws/dog2.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
        }, 'panel_4': {
            'title': 'Free Shipping Available',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new, personalised dog jigsaw puzzle with you within 3-5 working days.',
            // 'description2': 'They can be ordered with your option of:',
            'image': {
                'src': '/images/landingPages/jigsaws/dog3.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
        }, 'panel_5': {
            'title': 'Stylise And Add Flair',
            'description': 'Add stickers, effects, annotations and much more to your jigsaw, or just keep the image blank if you would prefer. With hundreds of ways to create your personalised dog jigsaw puzzle, the decision is yours!',
            'image': {
                'src': '/images/landingPages/stylise.jpg',
                'alt': 'Stylise your photo with Jigpaws'
            }
        }, 'panel_6': {
            'title': 'Should there be a final CTA',
            'description': 'here?',
            // 'description2': 'At Jigpaws, we offer free shipping on all our cards, it ' + 'has never been easier to get strong, detailed and fully ' + 'personalised cat cards delivered straight to your door!',
            'image': {
                'src': '/images/landingPages/jigsaws/dog3.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
        },
    },
    'cat-jigsaws': {
        'consistent': {
            'createLink': '/product/jigsaw',
            'price': 'jigsaw-500-50x38',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Cat Jigsaw Puzzles | Create | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised cat jigsaw puzzles with Jigpaws. Here, you can upload a picture of your cat and instantly see it on a jigsaw! Then you can order it straight to your door.\n',
            'og_url': 'https://www.jigpaws.com//gift-products/cat-jigsaws',
            'og_title': 'Personalised Cat Jigsaw Puzzles | Create | Jigpaws',
            'og_description': 'Create personalised cat jigsaw puzzles with Jigpaws. Here, you can upload a picture of your cat and instantly see it on a jigsaw! Then you can order it straight to your door.\n',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**   Order by the 20th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 13th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Personalised Cat Jigsaw Puzzles with Jigpaws',
            'description': 'Welcome to Jigpaws, where you can create your own, fully customisable cat jigsaw puzzle online in seconds.\n',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'PERSONALISED CAT JIGSAWS',
            'description': 'Are you searching for a unique way to enjoy or cherish your favourite cat photos? Are you wanting to pay homage to a friend\'s iconic cat? Then how about creating a fully personalised cat jigsaw puzzle? With options for either 500 or 1000 pieces, these cat jigsaw puzzles are purrrrfect for both casual puzzlers and those seeking a challenge.',
            'image': {
                'src': '/images/landingPages/jigsaws/cat1.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet cat photo'
            }
        }, 'panel_2': {
            'title': 'Add Your Image',
            'description': 'Get ready to unwind and have fun with this fully unique, personalised cat jigsaw puzzle!',
            'description2': 'To create a personalised cat jigsaw puzzle, simply upload a high-quality photo of your or someone else’s beloved cat, which can be taken from a smartphone, tablet or digital camera. It will then appear in vibrant colour on your brand new jigsaw puzzle.',
            'image': {
                'src': '/images/landingPages/uploadJigsaw.jpg',
                'alt': 'Upload a photo with Jigpaws'
            }
        }, 'panel_3': {
            'title': 'Pick Your Size',
            'description': 'Each cat jigsaw puzzle is made by hand, meaning that we can offer 2 sizes:\n',
            'list': '• 500 pieces (50x38cm)\n' +
                '• 1000 pieces (66x50cm)\n',
            'image': {
                'src': '/images/landingPages/jigsaws/cat2.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet cat photo'
            }
        }, 'panel_4': {
            'title': 'Delivered To You',
            'description': 'With free shipping available to the mainland UK, Jigpaws can have your new, personalised cat jigsaw puzzle with you within 3-5 working days.',
            // 'description2': 'They can be ordered with your option of:',
            'image': {
                'src': '/images/landingPages/jigsaws/cat3.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet cat photo'
            }
        }, 'panel_5': {
            'title': 'Stylise And Add Flair',
            'description': 'With hundreds of ways to create your personalised cat jigsaw puzzle, the decision is yours! Add stickers, effects, annotations and much more to your jigsaw, or just keep the image if you prefer.',
            'image': {
                'src': '/images/landingPages/stylise.jpg',
                'alt': 'Stylise your photo with Jigpaws'
            }
        }, 'panel_6': {
            'title': 'Should there be a final CTA',
            'description': 'here?',
            // 'description2': 'At Jigpaws, we offer free shipping on all our cards, it ' + 'has never been easier to get strong, detailed and fully ' + 'personalised cat cards delivered straight to your door!',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-card.jpg',
                'alt': 'Jigpaws personalised gift photo card with pet cat photo'
            }
        },
    },
    'personalised-jigsaws': {
        'consistent': {
            'createLink': '/product/jigsaw',
            'price': 'jigsaw-500-50x38',
            'priceFrom': 'true',
        },
        'head': {
            'title': 'Personalised Jigsaw Puzzles | Make Your Own Jigsaw | Jigpaws'
        }, 'meta': {
            'description': 'Create personalised jigsaw puzzles with us here at Jigpaws. Choose an image, customise your Jigsaw using our online tool, and ship them directly to your door. Create with Jigpaws today!',
            'og_url': ' https://www.jigpaws.com/gift-products/personalised-jigsaws',
            'og_title': 'Personalised Jigsaw Puzzles | Make Your Own Jigsaw | Jigpaws',
            'og_description': 'Create personalised jigsaw puzzles with us here at Jigpaws. Choose an image, customise your Jigsaw using our online tool, and ship them directly to your door. Create with Jigpaws today!',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'banner' : {
    "sectionTitle": "Pre-Christmas delivery",
    "items" : [
    {
    'description': 'To ensure Santa’s little helpers have time to create your order please order before the following dates to ensure standard* pre-chrismas delivery.',
},
        {
    'description': "**UK:**   Order by the 20th of December for pre-Christmas delivery.\n" +
        "**ROW:** Order by the 13th of December for pre-Christmas delivery.\n" +
        "\n" +
        "*Express and next day will ship as normal.",
},
    ]
    },
'header': {
            'title': 'Create Your Own Personalised Jigsaw Puzzle with Jigpaws',
            'description': 'Here at Jigpaws, creating your own personalised jigsaw puzzle is easy, and we will ship it right to your doorstep. Now with free UK mainland delivery available.',
            // 'description2': 'Once you have completed your fully customisable and personalised ' + 'cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'panel_1': {
            'title': 'Personalised Jigsaw Puzzles Made Easy',
            'description': 'If you are looking for the perfect solution for a loved one’s birthday, or if you are looking to celebrate a cherished memory, then why not create a personalised jigsaw with Jigpaws?\n\n' +
                'Our Jigsaws are entirely customisable and can include any image that you wish. Each is printed on a durable, heavy-duty card with a glossy finish.',

            'image': {
                'src': '/images/landingPages/jigsaws/generic1.webp',
                'alt': 'Jigpaws personalised gift jigsaw'
            }
        }, 'panel_2': {
            'title': 'Add An Image To Your Personalised Jigsaw Puzzle',
            'description': 'Upload your image of a loved one, family photo, wedding day image or holiday vista and see it appear on a Jigsaw, instantly.',
            'description2': 'Using our online tool you can create a personalised jigsaw puzzle in a matter of minutes and order it to your door in as little as 3-5 working days.',
            'image': {
                'src': '/images/landingPages/uploadJigsaw.jpg',
                'alt': 'Upload your image with Jigpaws',
            }
        }, 'panel_3': {
            'title': 'Personalised Jigsaw Puzzle - 500 Pieces or 1000 Pieces',
            'description': 'Each Jigpaws puzzle is made by hand. This means you can get a personalised jigsaw puzzle in various sizes. \n\n If you’re looking for a jigsaw puzzle that takes up a whole weekend, or something a little simpler, then we have the selection for you. Here at Jigpaws, we stock both 500 and 1000-piece jigsaw puzzles.',
            'list': '• 500 pieces (50x38cm)\n • 1000 pieces (66x50cm)\n',
            'image': {
                'src': '/images/landingPages/jigsaws/generic2.webp',
                'alt': 'choose your jigsaw with Jigpaws'
            }
        }, 'panel_4': {
            'title': 'Order Your Personalised Jigsaw',
            'description': 'We offer free shipping to mainland UK destinations. We estimate that your personalised jigsaw puzzle can be with you in as little as 3 to 5 working days.',
            'image': {
                'src': '/images/landingPages/jigsaws/generic3.webp',
                'alt': 'Jigpaws personalised gift jigsaw'
            }
        }, 'signOffSlider': {
            'title': 'Personalised Jigsaws and Much More',
            'description': 'Here at Jigpaws, we don’t just create wonderful personalised jigsaw puzzles for you to enjoy. We also create many other fully personalised gift products, so why not take a look through our range?',
            'list': '• Cards\n • Notebooks\n • Photo Tiles\n • Cushions\n• Photo Books\n',
        },
        'faq': {
            "sectionTitle": "Personalised Jigsaws FAQs",
            "items": [
                {
                    'title': 'What Is A Personalised Jigsaw Puzzle?',
                    'description': 'A personalised Jigsaw puzzle has been created to include an image chosen by you. Using Jigpaws, you can create a totally custom, personalised jigsaw puzzle which includes an image of your family, friends or even pets, instantly!',
                },
                {
                    'title': 'How Do I Create A Personalised Jigsaw Puzzle Online?',
                    'description': 'Using our online editing tool, you can create a personalised jigsaw puzzle online instantly. Upload an image from your computer, tablet or smartphone and see it on a jigsaw in real time. From here, you can make adjustments or enhancements, and order the jigsaw right to your door in just a few working days.',
                },
                {
                    'title': 'What Sizes Are Available For Personalised Jigsaw Puzzles?',
                    'description': 'Our personalised jigsaw puzzles are available in 2 sizes, 500pc (50cm x 38cm) and 1000pc (66cm x 50cm).',
                },
                {
                    'title': 'How Many Pieces Are In A Personalised Jigsaw Puzzle?',
                    'description': 'Our jigsaws are available as 500-piece sets or 1000-peace sets.',
                },
                {
                    'title': 'What Is The Cost Of A Personalised Jigsaw Puzzle?',
                    'description': 'Our jigsaws are available from just £28 for our smaller, 500-piece sets, or £32 for our larger 1000-piece sets.',
                },
                {
                    'title': 'What Is The Cost Of A Personalised Jigsaw Puzzle?',
                    'description': 'Our jigsaws are available from just £28 for our smaller, 500-piece sets, or £32 for our larger 1000-piece sets.',
                },
                {
                    'title': 'Can I Use Any Photo For My Personalised Jigsaw Puzzle?',
                    'description': 'Yes, our online tool can instantly convert any style of image, including JPEG, PNG or even WebP.',
                },
                {
                    'title': 'How Long Does It Take To Receive A Personalised Jigsaw Puzzle?',
                    'description': 'Our jigsaws are created completely bespoke to you. We deliver within 3 - 5 working days.',
                }, {
                    'title': 'Are Personalised Jigsaw Puzzles Suitable For Gifting?',
                    'description': 'Yes, in fact, personalised jigsaw puzzles are a great way to gift a cherished memory to a loved one. Not only do they get to enjoy the picture of a memory you spent together, but they also get to build a jigsaw puzzle!',
                },
                {
                    'title': 'What Materials Are Used For The Jigsaw Puzzles?',
                    'description': 'Our Jigsaws are entirely customisable and can include any image that you wish. Each is printed on a durable, heavy-duty card with a glossy finish.',
                },
                {
                    'title': 'Are Personalised Jigsaw Puzzles Suitable For Gifting?',
                    'description': 'Yes, in fact, personalised jigsaw puzzles are a great way to gift a cherished memory to a loved one. Not only do they get to enjoy the picture of a memory you spent together, but they also get to build a jigsaw puzzle!',
                },
            ]
        },
    },
}


export default productData;