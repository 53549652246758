// SliderSection.js
import React from "react";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";
import SliderItem from "./SliderItem";
import CenterGrid from "../CenterGrid";
import { slider_settings } from "../LandingPage/functions_LandingPage.js";
import {makeStyles} from '@mui/styles';
import ReactGA from 'react-ga';


const useStyles = makeStyles((theme) => ({
    appStore: {
        display: "block",
        margin: "0 auto",
        [theme.breakpoints.down('md')]: {
            width: "80%",
        },
    },
}));



const onGotoAppStore = (e) => {
    ReactGA.event({
        category: "User",
        action: 'Clicked through to appstore',
        label: "https://apps.apple.com/gb/app/jigpaws/id1541336283"
    })
};

const SliderSection = ({ includeCustomAppleSection }) => {

    

    const classes = useStyles();

    // if (includeCustomAppleSection) {
        return (
            <Grid item xs={12} md={6} className="bgBlue">
                <Slider className="slickText" {...slider_settings}>
                    <SliderItem
                        title="Facebook review: Arrived today. Quickly, with a decent courier and is absolutely amazing"
                        // buttonText="AVAILABLE NOW!"
                        // buttonLink="/product/photobook"
                        image="/images/customerReviews/1_facebook_1.webp"
                    />
                    <SliderItem
                        title="Beautiful Cushion. Easy to order. Very prompt delivery."
                        // buttonText="AVAILABLE NOW!"
                        // buttonLink="/product/photobook"
                        image="/images/customerReviews/2_trustpilot_1.webp"
                    />
                    <SliderItem
                        title="Free UK Shipping"
                        buttonText="AVAILABLE NOW!"
                        buttonLink="/create-personalised-pet-photo-gifts"
                    />

                            {/*    <CenterGrid>*/}
                            {/*    <Grid>*/}
                            {/*        <div className="spacer"/>*/}
                            {/*        <div><h3>Download the app</h3></div>*/}
                            {/*        <div className="body">play the jigsaw puzzle game</div>*/}
                            {/*        <div className="spacerMed"/>*/}

                            {/*        <a */}
                            {/*            alt="Apple App Store logo" */}
                            {/*            onClick={onGotoAppStore}*/}
                            {/*            href="https://apps.apple.com/gb/app/jigpaws/id1541336283" */}
                            {/*            target="_blank"*/}
                            {/*            rel="noopener noreferrer"*/}
                            {/*        >*/}
                            {/*           <img*/}
                            {/*            className={classes.appStore} */}
                            {/*            alt="Apple App Store"*/}
                            {/*            src="/images/APP-STORE-LOGO.png"*/}
                            {/*            style={{"width": "200px"}}/></a>*/}

                            {/*        <div className="spacer"/>*/}
                            {/*    </Grid>*/}
                            {/*</CenterGrid>*/}
                    <SliderItem
                        title="Just received mine today absolutely love it"
                        // buttonText="AVAILABLE NOW!"
                        // buttonLink="/product/photobook"
                        image="/images/customerReviews/3_facebook_3.webp"
                    />
                    <SliderItem
                        title="Quality from top to bottom!!!!! Quality from top to bottom! The service-outstanding; the product-top notch!"
                        // buttonText="AVAILABLE NOW!"
                        // buttonLink="/product/photobook"
                        image="/images/customerReviews/4_trustpilot_2.webp"
                    />
                    <SliderItem
                        title="30% OFF CARDS"
                        body="when you buy 10 cards or more."
                        buttonText="Create Now"
                        buttonLink="/product/greeting-card"
                    />
                </Slider>
            </Grid>
        );
    // } else {
    //     return (
    //         <Grid item xs={12} md={6} className="bgBlue">
    //             <Slider className="slickText" {...slider_settings}>
    //                 <SliderItem
    //                     title="FREE UK SHIPPING"
    //                     buttonText="CREATE NOW"
    //                     buttonLink="/create-personalised-pet-photo-gifts"
    //                 />
    //                 <SliderItem
    //                     title="30% OFF CARDS"
    //                     body="when you buy 10 or more"
    //                     buttonText="CREATE NOW"
    //                     buttonLink="/product/greeting-card"
    //                 />
    //                 <SliderItem
    //                     title="Photobook"
    //                     buttonText="AVAILABLE NOW!"
    //                     buttonLink="/product/photobook"
    //                 />
    //             </Slider>
    //         </Grid>
    //     );
    // }
};

export default SliderSection;
