
// JigpawAppBar.js
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signOut } from "firebase/auth";
import { AppBar, Toolbar, IconButton, Container, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Link from '@mui/material/Link';
import ReactGA from "react-ga";
import { useStyles } from './styles';
import { CurrencyIcon } from './CurrencyIcon';
import { CartLink } from './CartLink';
import { CurrencyMenu } from './CurrencyMenu';
import { ProfileMenu } from './ProfileMenu';
import { MobileDrawer } from './MobileDrawer';
import { app } from "../../firebase";

function usePageViews() {
    let location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [location]);
}

export default function JigpawAppBar() {
    usePageViews();
    const classes = useStyles();
    const auth = getAuth(app);
    const [user] = useAuthState(auth);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currencyAnchorEl, setCurrencyAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isCurrencyMenuOpen = Boolean(currencyAnchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));

    const handleCurrencyMenuOpen = (event) => {
        setCurrencyAnchorEl(event.currentTarget);
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleCurrencyMenuClose = () => {
        setCurrencyAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleSignOut = () => {
        handleMenuClose();
        signOut(auth).catch((error) => {
            console.error("Failed to sign out:", error);
        });
    };

    if (isMobileMenuOpen && isDesktopScreen) {
        handleMenuClose();
    }

    const toggleMobileMenu = (event) => {
        if (isMobileMenuOpen) {
            handleMobileMenuClose();
        } else {
            handleMobileMenuOpen(event);
        }
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    // DESKTOP MENU
    return <>
        <div className={classes.grow}>
            <AppBar position="fixed" className={classes.appBar}>
                <Container maxWidth={false} disableGutters className="jpNav">
                    <Toolbar className={classes.toolbarSecondary}>
                        <IconButton
                            edge="start"
                            className={`${classes.mobileMenuButton} jpIcon`}
                            color="inherit"
                            onClick={toggleMobileMenu}
                            size="large">
                            {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                        <div className="logoSize">
                            <Link to='/' component={RouterLink} underline="hover">
                                <img alt="jigpaws" src="/images/svg/jigpawsLogoWhite.svg" style={{ maxHeight: "20px", display: "block" }} />
                            </Link>
                        </div>
                        <div className={classes.grow} />
                        <Link underline="none" component={RouterLink} color="inherit" noWrap variant="h6" to='/' className={classes.toolbarLink}>
                            HOME
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit" noWrap variant="h6" to='/gifts/gifts' className={classes.toolbarLink}>
                            GIFTS
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit" noWrap variant="h6" to='/gifts/dogs' className={classes.toolbarLink}>
                            DOG GIFTS
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit" noWrap variant="h6" to='/gifts/cats' className={classes.toolbarLink}>
                            CAT GIFTS
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit" noWrap variant="h6" to='/gifts/pets' className={classes.toolbarLink}>
                            PET GIFTS
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit" noWrap variant="h6" to='/create' className={classes.toolbarLink}>
                            CREATE
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit" noWrap variant="h6" to='/gallery' className={classes.toolbarLink}>
                            GALLERY
                        </Link>
                        <Link underline="none" component={RouterLink} color="inherit" noWrap variant="h6" to='/blog' className={classes.toolbarLink}>
                            BLOG
                        </Link>
                        <div className={classes.grow} />
                        <div className={classes.menuIcons}>
                            {user && <CurrencyIcon user={user} handleCurrencyMenuOpen={handleCurrencyMenuOpen} />}
                            <IconButton
                                className={"jpIcon"}
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={'primary-account-menu'}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                                size="large">
                                <AccountCircle />

                            </IconButton>
                            {user && <CartLink user={user} />}
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            <MobileDrawer
                isMobileMenuOpen={isMobileMenuOpen}
                handleMobileMenuClose={handleMobileMenuClose}
                handleMobileMenuOpen={handleMobileMenuOpen}
                classes={classes}
            />
            <ProfileMenu
                anchorEl={anchorEl}
                isMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                handleSignOut={handleSignOut}
                user={user}
            />
            <CurrencyMenu
                user={user}
                anchorEl={currencyAnchorEl}
                isMenuOpen={isCurrencyMenuOpen}
                handleMenuClose={handleCurrencyMenuClose}
            />
        </div>
        <div className={classes.toolbarOffset}></div>
    </>;
}
