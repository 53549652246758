import React from "react";
import { Box } from "@mui/material";
import BalanceText from "react-balance-text";
import { StyledButton } from "./styledComponents";
import { useHistory } from "react-router-dom";

const textStyles = {
  fontSize: "20px",
  fontWeight: 500,
  letterSpacing: "0.03em",
  "@media (max-width: 1280px)": {
      fontSize: "15px",
    },
};




const JPTileInner = ({ title, bodyText1, bodyText2, buttonText, buttonHref, balanceTile, titleTag = "h2" }) => {

  const history = useHistory()

  const handleButtonClick = () => {
    history.push(buttonHref);
  };
  const TitleTag = titleTag; // Dynamically set the tag


  // console.log(buttonHref)
  return (
    <div className="jpTileInner">
      <div className="spacerMed" />
      {balanceTile ? (
        <TitleTag>
          <BalanceText>{title}</BalanceText>
        </TitleTag>
      ) : (
        <TitleTag>{title}</TitleTag>
      )}
      <div className="spacerMed" />
      <div className="body">
        {balanceTile ? (
          <BalanceText>
            <Box component="p" sx={textStyles}>{bodyText1}</Box>
          </BalanceText>
        ) : (
          <Box component="p" sx={textStyles}>{bodyText1}</Box>
        )}
        <div className="spacerSmall" />
        {balanceTile ? (
          <BalanceText>
            <Box component="p" sx={textStyles}>{bodyText2}</Box>
          </BalanceText>
        ) : (
          <Box component="p" sx={textStyles}>{bodyText2}</Box>
        )}
      </div>
      <div className="spacerMed" />
      <StyledButton type={"bigwhite"} onClick={handleButtonClick}>
        {buttonText}
      </StyledButton>
    </div>
  );
};

export default JPTileInner;
