//REACT
import React from "react"

//FIREBASE

//MATERIALS
import Grid from "@mui/material/Grid";
import {Container } from "@mui/material";

//INTERNAL IMPORTS
import {useLocale} from "../../api-locale";

//EXTERNAL IMPORTS
import BalanceText from "react-balance-text";
import Helmet from "react-helmet";
// import styled from "styled-components"


//COMPONENTS
import JigpawImages from "../components/JigPawImages";
import PhotobookProduct from "../components/PhotoBookProduct";
import {DelayedLinearProgress} from "../../global/components/ProgressBars";
import CenterGrid from "../../CenterGrid";
import SliderSection from "../../ShippingFooter/SliderSection";
import { Body, BigP, BigBody } from "../../styledComponents";
import {getAuth, signOut} from "firebase/auth";



//HOOKS, UTILS, DATA
import useStyles from "../components/useStyles";
import Link from "@mui/material/Link";

const pStyles = {
    fontSize: '18px',
    '@media (max-width: 600px)': {
        fontSize: '16px',

    },
}





const CreateInner = ({user}) => {
    // Items state.
    const classes = useStyles();
    //todo
    // const {locale, loading, e} = useLocale(user);
    const {locale} = useLocale(user);
    // console.log(JSON.stringify(user,null,2))
     // Local sign-out handler
    const handleSignOut = () => {
        // Implement the sign-out logic, e.g., Firebase sign-out
        console.log("Signing out...");
        // Example: firebase.auth().signOut();
    };
    if (locale) {
        return (
            <>
                <Helmet>
                    <title>Create personalised photo gifts | Jigsaws | Cards | Journal | Cushions</title>
                    <meta name="description"
                          content="Upload your favourite pet photos to create the perfect personalised photo gifts for any occasion – birthdays, anniversaries, valentines, Christmas!"/>
                    {/*<link rel="canonical" href="https://www.jigpaws.com/create-personalised-pet-photo-gifts"/>*/}
                    <meta property="og:url"
                          content="https://www.jigpaws.com/create-personalised-pet-photo-gifts"/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:title" content="Jigpaws – Create"/>
                    <meta property="og:description"
                          content="Create the perfect photo gift today."/>
                    <meta property="og:image" content="https://www.jigpaws.com/images/jigpaws-social-3.jpg"/>
                </Helmet>


                <Container className={`productsHome bgGray`} align="center" disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div className="headerContainer">
                        {user.isAnonymous &&
                            <>
                                <div><h1><BalanceText>How Do I Personalise My Pet Gifts?</BalanceText></h1></div>

                                <BigBody className="body">
                                    <BalanceText>Creating your personalised pet gifts with Jigpaws has never been easier. Simply pick one of our favourite pictures, or upload one of your own.
                                    </BalanceText>
                                <div className="spacerSmall"/>
                                <BalanceText>From there, you can view your new personalised pet gift before continuing to our free shipping page.</BalanceText>
                                </BigBody>
                            </>
                        }

                        {!user.isAnonymous &&
                            <>
                                <div><h1><BalanceText>Welcome Back</BalanceText></h1></div>
                                <BigP className="body"><BalanceText>Creating your personalised pet gifts with Jigpaws has never been easier. Simply pick one of our favourite pictures, or upload one of your own.</BalanceText>
                                <div className="spacerSmall"/> <BalanceText>From there, you can view your new personalised pet gift before continuing to our free shipping page.</BalanceText></BigP>
                            </>
                        }
                    </div>

                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>

                </Container>
                <div className="spacer"/>
                <Container maxWidth="xxl" disableGutters className={classes.createPageFix}>
                    {/*<div className="leftText"><h3>My Account</h3></div>*/}

                    {!user.isAnonymous && (
                        <div className="user-info leftText">
                            <div>
                                Logged in as <strong>{user.email}</strong>
                            </div><div>
                                <Link
                                    onClick={handleSignOut}
                                    underline="hover"
                                    style={{cursor: 'pointer'}}
                                >
                                    Sign out
                                </Link>
                            </div>
                        </div>
                    )}


                    {!user.isAnonymous &&
                        <>
                            <div className="leftText"><h3>My Drafts</h3></div>
                            <Grid container spacing={2} className={classes.imageGrid}>
                                <Grid item xs={6} sm={4} md={3} lg={4} xl={2}>
                                    <PhotobookProduct user={user} currency={locale.currencyCode}></PhotobookProduct>
                                </Grid>
                            </Grid>
                        </>
                    }
                    {!user.isAnonymous &&
                        <>
                    <h3 className="leftText">My Photos</h3></> }
                    <div className={classes.imageGridContainer}>
                        <JigpawImages user={user}></JigpawImages>
                        <div className="spacer"/>
                    </div>
                </Container>
                <Container disableGutters={true} maxWidth={false}>
                <div className="jpDivider bgGray"/>

                    {/*<Container maxWidth="lg">*/}

                    <Grid container>
                        <Grid item xs={12} md={6} className="bgGreen">
                            <CenterGrid>
                                <Grid>
                                    <div className="spacer desktopNone"/>

                                    <img className={classes.appStore} src="/images/svg/jpFreeShipping.svg"
                                         alt="Speeding delivery truck"
                                         style={{"maxWidth": "140px"}}/>
                                    <div className="spacerSmall"/>
                                    <div><h3>Free UK Shipping!</h3></div>
                                    <div className="spacerSmall"/>
                                    <BigP className="body">International and next day<br/>delivery also available</BigP>
                                    <div className="spacer desktopNone"/>

                                </Grid>
                            </CenterGrid>
                        </Grid>
                        <SliderSection includeCustomAppleSection={true} />
                    </Grid>

                </Container>

            </>
        );
    }
    return (
        <DelayedLinearProgress/>
    );
};

export default CreateInner