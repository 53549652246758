import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';

import Helmet from "react-helmet";


const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        background: '#fff',
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        flexGrow: 1,
        width: 1024,
        margin: "auto",
        [theme.breakpoints.down('lg')]: {
            width: "100%",
            margin: "none,"
        },
    },
    header: {
        background: '#fff',
        color: '#413e40',
        textAlign: "left",
        marginLeft: 40,
        marginTop: 40,
        marginBottom: 20,
    },
    section: {
        background: '#fff',
        color: '#413e40',
        textAlign: "left",
        marginLeft: 40,
        marginTop: 20,
        marginBottom: 20,
        marginRight: 40,
    }
}));


export default function Deliveries() {
    const classes = useStyles();

    return (

        <React.Fragment>
            <Helmet>
                <title>Jigpaws – Free shipping | Delivery times and FAQs</title>
                <meta name="description"
                      content="Free shipping | Delivery times and FAQs"/>
                {/*<link rel="canonical" href="https://www.jigpaws.com/deliveries-and-returns"/>*/}
                <meta property="og:url"
                      content="https://www.jigpaws.com/deliveries-and-returns"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="Jigpaws – Free shipping | Delivery times and FAQs"/>
                <meta property="og:description"
                      content="Simply upload one of your many pet photos and create a lovingly crafted personalised pet photo gift to share with friends and family around the world."/>
                <meta property="og:image" content="https://www.jigpaws.com/images/social-logo.png"/>
            </Helmet>
            <div className={classes.container}>
                <Container className={"productsHome bgGray"} disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div><h1>DELIVERIES & RETURNS</h1></div>
                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>

                </Container>

                <div className={classes.wrapper}>

                    <Paper elevation={0} square={true} className={classes.section}>


                    </Paper>
                    <Paper elevation={0} square={true} className={classes.section}>
                        <h4>Deliveries</h4>
                        <body>
                        <div className="tcCopy">
                        <p>All items are printed and handmade to order.
                        We offer FREE DELIVERY within the UK.
                        Plus, we offer FREE INTERNATIONAL delivery on all jigsaw puzzles – all other items delivery costs will be calculated at checkout.<br/>
                                                        <div className="spacerMed"/>

                        Please see below for our production and shipping times:<br/><div className="spacerSmall"/>
                            <strong>Jigsaw puzzles:</strong><br/>3–5 days for production and 1–3 days for UK delivery. ROW may take up to 10 days.<br/><div className="spacerSmall"/>
                        <strong>Cushions:</strong><br/> 3–5 days for production and 1–3 days for UK delivery. ROW may take up to 10 days.<br/><div className="spacerSmall"/>
                        <strong>Photobooks:</strong><br/> 3–5 days for production and 1–3 days for UK delivery. ROW may take up to 10 days.<br/><div className="spacerSmall"/>
                        <strong>Photo Tiles:</strong><br/> 2–4 days for production and 1–3 days for UK delivery. ROW may take up to 10 days.<br/><div className="spacerSmall"/>
                        <strong>Notebooks:</strong><br/> 1–3 days for production and 1–3 days for UK delivery. ROW may take up to 10 days.<br/><div className="spacerSmall"/>
                        <strong>Cards:</strong><br/> 1–3 days for production and 1–3 days for UK delivery. ROW may take up to 10 days.<br/><div className="spacerSmall"/>
                                                        <div className="spacerMed"/>

                        Once shipped you will receive a confirmation email with your tracking details.<br/>
                        If your order has not arrived 3 working days after dispatch for domestic orders, or 10 working days after dispatch for international orders, please contact <Link underline={"none"} variant={"p"} className={classes.link} rel="noopener"
                                             href="mailto:support@jigpaws.com">support@jigpaws.com</Link> com and we will look into this for you.</p>
                        </div>
                        </body>
                    </Paper>
                    <Paper elevation={0} square={true} className={classes.section}>
                        <h4>Christmas</h4>
                        <body>
                        <div className="tcCopy">
                        <p>Please place your order before the 17th of December to allow Father Christmas time to drop your gifts off.

</p>
                        </div>
                        </body>
                    </Paper>
                    <Paper elevation={0} square={true} className={classes.section}>
                        <h4>Returns & Refunds</h4>
                        <body>
                        If you're not happy with your order let us know and we will happily offer a replacement or refund, if the items you receive are faulty or damaged.
                        <br/>
                        Please refer to our Returns & Refund Policy in our <Link underline={"none"}
                                                                                 className={classes.link} rel="noopener"
                                                                                 target="_blank"
                                                                                 href="/legal/terms-and-conditions">Terms
                            of Use</Link> for more details.<br/>
                        All refunds will be processed and returned to the original purchase method within 5 - 10 working days.
<br/><br/>
                        Please contact <Link underline={"none"} variant={"p"} className={classes.link} rel="noopener"
                                             href="mailto:support@jigpaws.com">support@jigpaws.com</Link> if you have
                        not received your refund within 20 days.
                        </body>
                        <div className="spacer" />
                    </Paper>
                </div>
            </div>
        </React.Fragment>
    );
}